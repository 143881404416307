<template>
    <nuxt/>
</template>

<script>
export default {
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>
    
</style>